<template>
  <div class="errPage-container">
    <img
        :src="errGif"
        class="img"
        width="324px"
        height="269px"
        alt="Girl has dropped her ice cream."
    />
    <h3 class="title">
      <span v-if="timeStatus === '4'">填写完成，链接已失效</span>
      <span v-else>链接已失效</span>
    </h3>
    <p class="prompt-info">
<!--      <span v-show="timeStatus === '3'">该链接已超过24小时</span>-->
      <span v-show="timeStatus === '4'">该调令内容已填写完成，请<span @click="checkAssigment" style="color: #3182CE;cursor:pointer">点击此处</span>查看调令内容</span>
      <span v-show="timeStatus === '3'">链接超时，请<span @click="sendNewEmail" style="color: #3182CE;cursor:pointer">点击此处</span>触发新的邮件和链接</span>
      <span v-show="timeStatus === '5'">本需求PM已有选定人员</span>
      <span v-show="timeStatus === '2'">RM因{{rmRejectReason}}撤回需求匹配</span>
      <span v-show="timeStatus === '6'">该调令内容已填写完成</span>
    </p>
  </div>
</template>

<script>
import errGif from "@/assets/NoLogin/nologin.png";
import {computed, ref, watch} from "vue";
import {message} from "ant-design-vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {useRoute} from "vue-router";

export default {
  name: "LinkFailure",
  emits:['checkAssInfo'],
  props: {
    isTimeStatus: String,
    formState: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errGif: errGif + "?" + +new Date(),
    };
  },
  setup(props,{ emit }) {
    const {$api} = useGlobalPropertyHook();
    const timeStatus = computed(() => props.isTimeStatus)
    const formData = computed(() => props.formState);

    const checkAssigment = () => {
      emit("checkAssInfo")
    }
    const route = useRoute();
    //获取序列号
    const serialNo = computed(() => route.params.id)
    const sendNewEmail = () => {
      $api.reCreateInputLink(serialNo.value).then(() => {
        message.success('发送成功')
      });
    }
    const rmRejectReason = ref('')
    watch(
        () => props.formState,
        (val) => {
          if(val) {
            rmRejectReason.value = props.formState.rejectReason
          }
        },
        {immediate:true,deep:true}
    )

    return {
      timeStatus,
      formData,
      checkAssigment,
      sendNewEmail,
      serialNo,
      rmRejectReason,
    }
  }
}
</script>

<style scoped lang="less">
.errPage-container {
  width: 100%;
  height: calc(100vh - 2.8vw);
  background: #f7fafc;
  font-family: PingFang SC, Verdana, Genva, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3182ce;
  .img {
    width: 388.99px;
    height: 326.04px;
  }
  // 没权限 标题
  .title {
    font-size: 44px;
    line-height: 66px;
    font-weight: 500;
    color: #ABABAB;
    padding: 14px 0 14px;
    margin: 0;
  }
  // 提示信息
  .prompt-info {
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-weight: 500;
    font-size: 28px;
    text-align: center;
    color: #505050;
    margin: 0 0 30px;
    padding: 0;
    // 返回链接
    .link-type {
      font-size: 30px;
      color: #7604f9f9;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        color: #0493f9f9;
      }
    }
  }
}
</style>
