<template>
  <div class="main-nologin">
    <div class="page-header">
      <div class="logo">
        <img class="logo-img" src="@/assets/header/logo.png" alt="capgemini" />
      </div>
    </div>
    <div v-show="isTimeStatus === '0' && !checkAssignmentStatus">
      <NoLoginAssignment
          v-model:formState="formState"
          source="match"
          fillAssignmentType="PMFillAssignment"
          :accountNameOps="clientNameOps"
          :accountIndustryRelaction="accountIndustryRelaction"
          :originalCodeAndDateObj="originalCodeAndDateObj"
          :codeRelationList="codeRelationList"
          :optionInfo="optionInfo"
          :isTimeStatus="isTimeStatus"
      ></NoLoginAssignment>
    </div>
    <div v-show="(isTimeStatus === '1' || isTimeStatus === '2' || isTimeStatus === '3' || isTimeStatus === '4' || isTimeStatus === '5' || isTimeStatus === '6') && !checkAssignmentStatus">
      <LinkFailure
          v-model:formState="formState"
          :isTimeStatus="isTimeStatus"
          @checkAssInfo="checkAssignment"
      ></LinkFailure>
    </div>
    <div v-show="checkAssignmentStatus">
      <CheckAssignmentInfo
          v-model:formState="formState"
          :isFormalCode="isFormalCode"
          v-if="checkAssignmentStatus"
      ></CheckAssignmentInfo>
    </div>
  </div>
</template>

<script>
import LinkFailure from "@/views/singleSignOn/LinkFailure";
import NoLoginAssignment from "@/views/singleSignOn/NoLoginAssignment";
import {useGlobalPropertyHook} from "@/hooks/common";
import {computed, onMounted, reactive, ref, watch} from "vue";
import CheckAssignmentInfo from "@/views/singleSignOn/CheckAssignmentInfo";
import store from "@/store";
import {useRoute} from "vue-router";

export default {
  name: "index",
  components: {CheckAssignmentInfo, NoLoginAssignment, LinkFailure},
  setup() {
    const {$api} = useGlobalPropertyHook();
    const formState = reactive({
      rejectReason: "",
      serviceLine: "",
      subServiceLine: "",
      candidateName: "",
      candidateContact: "",
      candidateGrade: "",
      account: "",
      codeStartDate: "",
      codeEndDate: "",
      clientName: "",
      engagementCode: "",
      engagementDescription: "",
      workLocation: "",
      engagementManager: "",
      copyList: [],
      assignmentTerm: [],
      actualReleaseDate: "",
      hoursPerDay: "8",
      adrc: "",
      industryLine: "",
      workCity: undefined,
      replaceReason: "", // RM代PM填写原因
      pmInfo: "",
      projectCommitDate: "",
      userId: '',
      demandId: '',
      pmId: '',
      preMatchingId: '',
      id: '',
      projectCompletionDate: '',
      projectStatus: '',
    });
    const clientNameOps = ref([]);
    const accountIndustryRelaction = ref([])
    const originalCodeAndDateObj = ref({})
    const codeRelationList = reactive({
      relationsList: [],
      allCodeOptionsList: []
    })
    const route = useRoute();
    //获取序列号
    const serialNo = computed(() => route.params.id)

    //先判断有效性
    const isTimeStatus = ref('')

    const getTimeStatus = () => {
      //const serialNo = localStorage.getItem("originPath")
      $api.clickInputLink(serialNo.value).then((res) => {
        if(res.msg === '6') {
          isTimeStatus.value = '6'
        } else {
          isTimeStatus.value = res.data.status
          if (res.data.status === '0') {
            if (res.data.singleSignOnCommand.staffAssignment) {
              formState.serviceLine = res.data.singleSignOnCommand.serviceLine
              formState.subServiceLine = res.data.singleSignOnCommand.subServiceLine
              formState.candidateGrade = res.data.singleSignOnCommand.candidateGrade
              formState.adrc = res.data.singleSignOnCommand.adcr
              formState.demandClientName = res.data.singleSignOnCommand.ehrAccount
              formState.demandIndustryLine = res.data.singleSignOnCommand.ehrIndustryLine
              formState.candidateName = res.data.singleSignOnCommand.candidateName
              formState.candidateContact = res.data.singleSignOnCommand.userEmailAndPhone
              formState.account = res.data.singleSignOnCommand.staffAssignment.account
              formState.industryLine = res.data.singleSignOnCommand.staffAssignment.industryLine
              formState.hoursPerDay = res.data.singleSignOnCommand.staffAssignment.hoursPerDay
              formState.pmInfo = res.data.singleSignOnCommand.pmEmail
              formState.userId = res.data.singleSignOnCommand.userId
              formState.demandId = res.data.singleSignOnCommand.demandId
              formState.pmId = res.data.singleSignOnCommand.pmId
              formState.preMatchingId = res.data.singleSignOnCommand.preMatchingId

              formState.engagementCode = res.data.singleSignOnCommand.staffAssignment.engagementCode
              formState.engagementDescription = res.data.singleSignOnCommand.staffAssignment.engagementDescription
              formState.assignmentTerm = [res.data.singleSignOnCommand.staffAssignment.onBoardDate, res.data.singleSignOnCommand.staffAssignment.estimateReleaseDate]
              formState.codeStartDate = res.data.singleSignOnCommand.staffAssignment.codeStartDate
              formState.codeEndDate = res.data.singleSignOnCommand.projectStatus === 'Close'? res.data.singleSignOnCommand.staffAssignment.codeEndDate : res.data.singleSignOnCommand.projectCompletionDate
              formState.projectCommitDate = res.data.singleSignOnCommand.staffAssignment.projectCommitDate
              formState.engagementManager = res.data.singleSignOnCommand.staffAssignment.engagementManager
              formState.leadingCSM = res.data.singleSignOnCommand.staffAssignment.leadingCSM
              formState.workCity = res.data.singleSignOnCommand.staffAssignment.workCity
              formState.workLocation = res.data.singleSignOnCommand.staffAssignment.workLocation
              formState.copyList = res.data.singleSignOnCommand.staffAssignment.copyPersonName ? res.data.singleSignOnCommand.staffAssignment.copyPersonName.split(',') : []
              formState.rejectReason = res.data.singleSignOnCommand.rejectReason
              formState.id = res.data.singleSignOnCommand.staffAssignment.id

              clientNameOps.value = res.data.singleSignOnCommand.accountIndustryLineItems.map(item => item.account).map((item) => {
                return {
                  value: item,
                  label: item,
                  key: item,
                }
              });
              accountIndustryRelaction.value = res.data.singleSignOnCommand.accountIndustryLineItems;
              //不确定取值
              Object.assign(originalCodeAndDateObj.value, {
                engagementCode: res.data.singleSignOnCommand.staffAssignment.engagementCode,
                projectCommitDate: res.data.singleSignOnCommand.staffAssignment.projectCommitDate,
                onBoardDate: res.data.singleSignOnCommand.staffAssignment.onBoardDate,
              });
              codeRelationList.relationsList = res.data.singleSignOnCommand.codeTbdCodeRelations
              codeRelationList.allCodeOptionsList = [...new Set(codeRelationList.relationsList.map((item) => {
                return {
                  ...item,
                  label: item.codeAndDescription,
                  value: item.codeAndDescription,
                  type: item.type,
                }
              }))]
              optionInfo.value.EMOps = res.data.singleSignOnCommand.staffSearchCommands;
              getSelectOptions()
            } else {
              formState.serviceLine = res.data.singleSignOnCommand.serviceLine
              formState.subServiceLine = res.data.singleSignOnCommand.subServiceLine
              formState.candidateGrade = res.data.singleSignOnCommand.candidateGrade
              formState.adrc = res.data.singleSignOnCommand.adcr
              formState.demandClientName = res.data.singleSignOnCommand.ehrAccount
              formState.demandIndustryLine = res.data.singleSignOnCommand.ehrIndustryLine
              formState.candidateName = res.data.singleSignOnCommand.candidateName
              formState.candidateContact = res.data.singleSignOnCommand.userEmailAndPhone
              formState.account = res.data.singleSignOnCommand.account.account
              formState.industryLine = res.data.singleSignOnCommand.account.industryLine
              formState.hoursPerDay = res.data.singleSignOnCommand.hourPerDay
              formState.pmInfo = res.data.singleSignOnCommand.pmEmail
              formState.userId = res.data.singleSignOnCommand.userId
              formState.demandId = res.data.singleSignOnCommand.demandId
              formState.pmId = res.data.singleSignOnCommand.pmId
              formState.preMatchingId = res.data.singleSignOnCommand.preMatchingId

              clientNameOps.value = res.data.singleSignOnCommand.accountIndustryLineItems.map(item => item.account).map((item) => {
                return {
                  value: item,
                  label: item,
                  key: item,
                }
              });
              accountIndustryRelaction.value = res.data.singleSignOnCommand.accountIndustryLineItems;
              //不确定取值
              Object.assign(originalCodeAndDateObj.value, {
                engagementCode: res.data.singleSignOnCommand.engagementCode,
                projectCommitDate: res.data.singleSignOnCommand.projectCommitDate,
                onBoardDate: res.data.singleSignOnCommand.onBoardDate,
              });
              codeRelationList.relationsList = res.data.singleSignOnCommand.codeTbdCodeRelations
              codeRelationList.allCodeOptionsList = [...new Set(codeRelationList.relationsList.map((item) => {
                return {
                  ...item,
                  label: item.codeAndDescription,
                  value: item.codeAndDescription,
                  type: item.type,
                }
              }))]
              optionInfo.value.EMOps = res.data.singleSignOnCommand.staffSearchCommands;
              getSelectOptions()
            }
          }
          if (res.data.status === '2') {
            formState.rejectReason = res.data.reason
          }
        }
      });
    }

    const optionInfo = ref({
      EMOps:[],
      workCityOps: [],
    })

    const getSelectOptions = () => {
      const params= {
            dictType: "city",
          }
      $api
          .getPMCity(serialNo.value,params)
          .then((res) => {
            optionInfo.value.workCityOps = createSelectOptionData(res.data.city);
            store.commit("SET_DICTIONARY", res.data);
          });
    };
    // 构建select筛选options
    const createSelectOptionData = (data) => {
      const options = data.map((item) => {
        return {
          label: item.dictValue,
          value: item.dictValue,
          key: item.dictId,
        };
      });
      return options;
    };


    const checkAssignmentStatus = ref(false)
    const checkAssignment = async () => {
      if(isTimeStatus.value === '4'){
        await $api.getQueryLink(serialNo.value).then(async (r) => {
          await $api.queryAssignmentInfo(r.msg).then((res) => {
            formState.serviceLine = res.data.staffAssignment.serviceLine
            formState.subServiceLine = res.data.staffAssignment.subServiceLine
            formState.candidateGrade = res.data.staffAssignment.candidateGrade
            formState.adrc = res.data.ADRC
            formState.demandClientName = res.data.EHRAccount
            formState.demandIndustryLine = res.data.EHRIndustry
            formState.candidateName = res.data.staffAssignment.candidateName
            formState.candidateContact = res.data.staffAssignment.candidateContact
            formState.account = res.data.staffAssignment.account
            formState.industryLine = res.data.staffAssignment.industryLine
            formState.hoursPerDay = res.data.staffAssignment.hoursPerDay
            formState.pmInfo = res.data.pmInfo
            formState.engagementCode = res.data.staffAssignment.engagementCode
            formState.engagementDescription = res.data.staffAssignment.engagementDescription
            formState.assignmentTerm = res.data.staffAssignment.onBoardDate + "~" + res.data.staffAssignment.estimateReleaseDate
            formState.codeTerm = (res.data.staffAssignment.codeStartDate? res.data.staffAssignment.codeStartDate : '') + " ~ " + (res.data.projectStatus === 'Close'? res.data.staffAssignment.codeEndDate : res.data.projectCompletionDate)
            formState.projectCommitDate = res.data.staffAssignment.projectCommitDate
            formState.engagementManager = res.data.staffAssignment.engagementManager
            formState.leadingCSM = res.data.leadingCSM
            formState.workCity = res.data.staffAssignment.workCity
            formState.workLocation = res.data.staffAssignment.workLocation
            formState.copyList = res.data.staffAssignment.copyPersonName?.split(';')
            formState.staffAssignmentParts = res.data.staffAssignmentParts
            formState.staffAssignmentList = [res.data.staffAssignmentParts]
          })
          await $api.checkCodeByEngagementCode(serialNo.value,{engagementCode : formState.engagementCode}).then((res) => {
            isFormalCode.value = !res.data
          });
          checkAssignmentStatus.value = true
        })
      }
    }
    const isFormalCode = ref(true)

    watch(
        () => checkAssignmentStatus.value,
        (val) => {
          console.log(val)
        },
        {
          deep: true,
          immediate: true,
        }
    )

    onMounted(() => {
      getTimeStatus()
    });

    return {
      isTimeStatus,
      getTimeStatus,
      formState,
      clientNameOps,
      accountIndustryRelaction,
      originalCodeAndDateObj,
      codeRelationList,
      checkAssignment,
      checkAssignmentStatus,
      //getEMOptions,
      getSelectOptions,
      optionInfo,
      isFormalCode,
      serialNo,
    }
  }
}
</script>

<style scoped lang="less">
.main-nologin {
  .page-header {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 5px 14px rgba(24, 144, 255, 0.05);
    display: flex;

    .logo {
      // 宽度和菜单相同
      width: 350px;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-img {
        height: 43px;
        width: 172px;
        margin-top: 11px;
      }
    }
  }
}
</style>
