<template>
  <div>
    <div class="open-page">
      <div class="tag">
        <a-tag>查看调令内容</a-tag>
      </div>
    </div>
    <div class="main-form">
      <a-form
          :model="formData"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          layout="vertical"
      >
        <a-row>
          <a-col :span="6">
            <a-form-item label="Service Line:">
              {{ formData.serviceLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Sub Service Line:">
              {{ formData.subServiceLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Name:" name="candidateName">
              {{formData.candidateName}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Contact:" name="candidateContact">
              {{formData.candidateContact}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Request Account:">
              {{ formData.demandClientName }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Request Industry:">
              {{ formData.demandIndustryLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Grade:">
              {{ formData.candidateGrade }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="ADRC/人员成本:">
              {{ formData.adrc }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Account:" name="account">
              {{formData.account}}
            </a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="Industry Line:" name="industryLine">
              {{formData.industryLine}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Engagement Code:" name="engagementCode">
              {{formData.engagementCode}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Engagement Description:"
                name="engagementDescription"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 24 }"
            >
              {{formData.engagementDescription}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Code预计开出时间:"
                name="projectCommitDate"
            >
              {{isFormalCodeShow === true? '仅适用于TBD Code' : formData.projectCommitDate}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Code有效期:">
              {{ formData.codeTerm }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Engagement Manager:"
                name="engagementManager"
                class="selectEM"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 24 }"
            >
              {{formData.engagementManager}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="PM:" name="pmInfo">
              {{formData.pmInfo}}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
                label="Leading CSM:"
                name="leadingCSM"
                class="selectEM"
            >
              {{isFormalCodeShow === true? '仅适用于TBD Code' : formData.leadingCSM}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Assignment Term:" name="assignmentTerm">
              {{formData.assignmentTerm}}
            </a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="Hours per Day:" name="hoursPerDay">
              {{formData.hoursPerDay}}
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="formData.staffAssignmentParts?.length > 0">
              <a-table
                  :columns="columns"
                  :data-source="formData.staffAssignmentList"
                  :pagination="false"
                  class="segmentedClass"
                  row-key="id"
                  :scroll="{
                  x: 1000,
                  scrollToFirstRowOnChange: true,
                }"
              >
                <template #AssignmentTerm>
                  <div>
                    <span style="color: #1890FF">●</span>{{formData.assignmentTerm}}
                  </div>
                  <div v-for="(item) in formData.staffAssignmentParts" :key="item.id">
                    <span style="color: #77CAED">●</span><span style="color: #999999">{{item.startDate + '~' + item.endDate }}</span>
                  </div>
                </template>
                <template #hoursPerDay>
                  <div>
                    {{formData.hoursPerDay}}
                  </div>
                  <div v-for="(item) in formData.staffAssignmentParts" :key="item.id">
                    <span style="color: #999999">{{item.hoursPerDay}}</span>
                  </div>
                </template>
              </a-table>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Work Location:" name="workLocation">
              {{formData.workLocation}}
            </a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="Work City:" name="workCity">
              {{formData.workCity}}
            </a-form-item>
          </a-col>
          <a-col :span="24" class="tipsCol">
            <a-form-item
                label="Copy:"
                name="copyList"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 24 }"
            >
                <span v-for="item in formData.copyList" :key="item">
                  <a-tag>{{item}}</a-tag>
                </span>
            </a-form-item>
          </a-col>
<!--          <a-col :span="6" v-show="isShowComment">-->
<!--            <a-form-item label="RM Comments" name="replaceReason">-->
<!--              {{formData.replaceReason}}-->
<!--            </a-form-item>-->
<!--          </a-col>-->
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "CheckAssignmentInfo",
  components: {},
  props: {
    formState: {
      type: Object,
      default: () => ({
        serviceLine: "",
        subServiceLine: "",
        candidateName: "",
        candidateContact: "",
        candidateGrade: "",
        account: "",
        engagementCode: "",
        engagementDescription: "",
        workLocation: "",
        engagementManager: "",
        leadingCSM: "",
        copyList: [],
        assignmentTerm: '',
        estimateReleaseDate: "",
        hoursPerDay: "",
        adrc: "",
        industryLine: "",
        workCity: "",
        replaceReason: "",
        pmInfo: "",
        codeStartDate: "",
        codeEndDate: "",
      }),
    },
    isFormalCode: Boolean,
  },
  setup(props) {
    const formData = computed(() => props.formState);
    const isFormalCodeShow = computed(() => props.isFormalCode)
    const columns = [
      {
        title: "分段Assignment Term",
        dataIndex: "startDate",
        ellipsis: true,
        width: `26%`,
        slots: { customRender: "AssignmentTerm" },
      },
      {
        title: "分段Hours Per Day",
        dataIndex: "hoursPerDay",
        ellipsis: true,
        width: `74%`,
        slots: { customRender: "hoursPerDay" },
      },
    ];

    return {
      labelCol: { span: 12 },
      wrapperCol: { span: 20 },
      formData,
      isFormalCodeShow,
      columns,
    }
  }
}
</script>

<style scoped lang="less">
.open-page {
  height: 40px;
  display: flex;
  align-items: center;
  background: var(--unnamed, #FAFAFA);
  border-bottom: 0.1vw solid rgba(0,0,0,.08);

  .tag {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: auto;
    margin-left: 20px;
  }

  .ant-tag {
    background: #fff;
    padding: 0.4vw 1.8vw 0.4vw 1.5vw;
    color: #303133;
    position: relative;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    border: 0 solid #d9d9d9;
    border-top: 0.10417vw solid #3182CE;
  }

  .active-page-tag {
    background: #ECF5FE;
    border-top: 0.10417vw solid #3182CE;
  }
}
.main-form{
  height: calc(100vh - 200px);
  .ant-form{
    margin: 20px 0 0 40px;

    .ant-calendar-picker-input {
      border: 1px solid #cccccc !important;
    }
  }
  :deep(.ant-input){
    border: 1px solid #cccccc !important;
  }
  :deep(.ant-select-selector){
    border: 1px solid #cccccc !important;
  }
  :deep(.ant-select-arrow){
    color: #cccccc;
  }
  :deep(.ant-tag) {
    border: 0;
    background:#E8E8E8;
    color: #9F9F9F;
    font-size: 16px;
  }
  :deep(.ant-form-item-label > label){
    color: #999999;
    font-size: 0.9vw;
  }
  :deep(.ant-form-vertical .ant-form-item-label ){
    padding-bottom: 0.1vw;
    font-size:1vw;
    font-family: PingFang SC;
  }
}
.segmentedClass {
  margin-right: 4vw;
  :deep(.ant-table .ant-table-thead > tr > th){
    padding-left: 0;
  }
  :deep(.ant-table .ant-table-tbody > tr > td) {
    //padding-left: 0;
    //padding-right: 0;
    padding: 0.5vw 0 1vw;
  }
  .tilde{
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
    margin: 0 -0.16vw
  }
}
</style>
