<template>
  <div>
    <div class="open-page">
      <div class="tag">
        <a-tag>PM 确认匹配</a-tag>
      </div>
    </div>
    <div class="main-form">
      <div v-show="formData.rejectReason">
        <a-form
            :model="formData"
            :rules="formRules"
            ref="formRef"
        >
          <a-row>
            <a-col :span="24"  class="red-text">
              <a-form-item
                  label="Reject Reason:"
                  name="rejectReason"
              >
                {{ formData.rejectReason }}
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-form
          :model="formData"
          :rules="formRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          layout="vertical"
      >
        <a-row>
          <a-col :span="6">
            <a-form-item label="Service Line:" class="labelColor">
              {{ formData.serviceLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Sub Service Line:" class="labelColor">
              {{ formData.subServiceLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Name:" name="candidateName" class="labelColor">
              {{formData.candidateName}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Contact:" name="candidateContact" class="labelColor">
              {{formData.candidateContact}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Request Account:" class="labelColor">
              {{ formData.demandClientName }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Request Industry:" class="labelColor">
              {{ formData.demandIndustryLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="source === 'match'" class="labelColor">
            <a-form-item label="Candidate Grade:">
              {{ formData.candidateGrade }}
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="source === 'match'" class="labelColor">
            <a-form-item label="ADRC/人员成本:">
              {{ formData.adrc }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Account" name="account">
              <a-select
                  ref="selectCityRef"
                  v-model:value="formData.account"
                  :options="accountOpts"
                  placeholder="请选择"
                  allowClear
                  show-search
                  :getPopupContainer="getPopupContainer"
                  @change="handleAccountChange()"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="Industry Line" name="industryLine">
              <a-input
                  v-model:value="formData.industryLine"
                  disabled
                  style="width: 33.3%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6" class="engagementCodeCol">
            <a-form-item name="engagementCode">
              <template v-slot:label>
                <span class="codeLabel">Engagement Code</span>
                <a-popover placement="topLeft">
                  <template #content>
                    <div>如果查询不到，请<a href="https://teams.microsoft.com/l/chat/0/0?users=siyi.chen@capgemini.com" style="text-decoration:underline;color: #3182CE">联系管理员</a></div>
                  </template>
                  <span>
                  <QuestionCircleOutlined :style="{ color: '#3182ce'}" />
                  </span>
                </a-popover>
              </template>
              <a-select class="item"
                        allow-clear
                        placeholder="请选择"
                        show-search
                        optionFilterProp="label"
                        :options="engagementCodeOpts"
                        v-model:value="formData.engagementCode"
                        @change="handleCodeChange()"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Engagement Description"
                name="engagementDescription"
            >
              <a-input
                  v-model:value="formData.engagementDescription"
                  placeholder="请输入"
                  :maxlength="40"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Code预计开出时间"
                name="projectCommitDate"
            >
              <a-date-picker
                  class="item"
                  style="width: 100%"
                  allow-clear
                  type="date"
                  :placeholder="placeHolderTip"
                  valueFormat="YYYY-MM-DD"
                  :disabledDate="disabledCommitDate"
                  :inputReadOnly="true"
                  :showToday="false"
                  :disabled="isDisableProjectCommitDate"
                  v-model:value="formData.projectCommitDate"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Code有效期:" class="labelColor">
              {{ formData.codeTerm }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Engagement Manager"
                name="engagementManager"
                class="selectEM"
            >
              <a-select
                  ref="selectEMRef"
                  v-model:value="formData.engagementManager"
                  placeholder="请选择"
                  show-search
                  allow-clear
                  :getPopupContainer="getPopupContainer"
                  :disabled="isFormalCode === true ? !isAbleEm : false"
              >
                <a-select-option
                    v-for="em in EMOps"
                    :value="em.fullName + ' ' + em.email"
                    :key="em.fullName + ' ' + em.email"
                >
                  {{ em.fullName + " " + em.email }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="PM" name="pmInfo">
              <a-input
                  v-model:value="formData.pmInfo"
                  placeholder="请输入"
                  disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
                label="Leading CSM"
                name="leadingCSM"
                class="selectEM"
            >
              <a-select
                  ref="selectEMRef"
                  v-model:value="formData.leadingCSM"
                  :placeholder="placeHolderTip"
                  show-search
                  :show-arrow="false"
                  :getPopupContainer="getPopupContainer"
                  :disabled="!isAbleEm || isDisableProjectCommitDate"
                  style="width: 50%"
              >
                <a-select-option
                    v-for="em in EMOps"
                    :value="em.fullName + ' ' + em.email"
                    :key="em.fullName + ' ' + em.email"
                >
                  {{ em.fullName + " " + em.email }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="Assignment Term" name="assignmentTerm" class="assignmentTermClass" :wrapper-col="{ span: 24 }">
              <!--              <a-range-picker-->
              <!--                  style="width: 100%"-->
              <!--                  v-model:value="formData.assignmentTerm"-->
              <!--                  :disabled="modifyDisabled"-->
              <!--                  :disabled-date="disabledDate"-->
              <!--                  @change="handleAssChange"-->
              <!--              />-->
              <a-date-picker
                  v-model:value="formData.assignmentTerm[0]"
                  :disabledDate="modifyDisabled"
                  placeholder="请选择"
                  style="width: 48%"
                  @change="handleAssChange"
                  :disabled-date="onBoardDisabledDate"
                  :disabled="!isSegmentedEditing"
              />
              <div class="content">~</div>
              <a-date-picker
                  v-model:value="formData.assignmentTerm[1]"
                  :disabledDate="modifyDisabled"
                  placeholder="请选择"
                  style="width: 48%"
                  @change="handleAssChange"
                  :disabled-date="estimateReleaseDisabledDate"
                  :disabled="!isSegmentedEditing"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Hours per Day" name="hoursPerDay">
              <a-input
                  v-model:value="formData.hoursPerDay"
                  placeholder="请输入"
                  :disabled="!isSegmentedEditing"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label=" ">
              <a-button type="primary" @click="segmentedEditingClick" v-if="isSegmentedEditing">分段编辑</a-button>
              <a-button type="primary" @click="cancelSegmentedEditingClick" v-else>取消分段编辑</a-button>
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="!isSegmentedEditing">
            <a-form :model="segmentedData" ref="formRef" class="segmentedtaleclass" :rules="segmentedFormRules">
              <a-table
                  :columns="columns"
                  :data-source="segmentedData"
                  :pagination="false"
                  class="segmentedClass"
                  row-key="id"
                  :scroll="{
                  x: 1000,
                  scrollToFirstRowOnChange: true,
                }"
              >
                <template #startDate="{ index, text, record }">
                  <a-form-item :name="[index,'startDate']"
                               :rules="[
                               {
                                  validator: startValidatorSpace,
                                  startDateIndex: index,
                                  trigger: 'change',
                               }
                  ]"
                  >
                    <a-date-picker
                        :value="text"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                        @update:value="(val) => (segmentedData[index].startDate = val)"
                        style="width: 96%"
                        @change="startDateChange(index,record)"
                        :disabled-date="current => segmenteOnBoardDisabledDate(current, record,index)"
                        :disabled="modifyDisabled"
                    />
                    <span class="tilde"> ~ </span>
                  </a-form-item>
                </template>
                <template #endDate="{ index, text, record }">
                  <a-form-item :name="[index,'endDate']"
                               :rules="[
                               {
                                  validator: endValidatorSpace,
                                  endDateIndex: index,
                                  trigger: 'change',
                               }]"
                  >
                    <a-date-picker
                        :value="text"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                        @update:value="(val) => (segmentedData[index].endDate = val)"
                        style="width: 96%"
                        @change="endDateChange(index,record)"
                        :disabled-date="current => segmenteEstimateReleaseDate(current, record, index)"
                        :disabled="modifyDisabled"
                    />
                  </a-form-item>
                </template>

                <template #hoursPerDay="{ index, text }">
                  <a-form-item :name="[index, 'hoursPerDay']"
                               :rules="[
                                 {
                                  required: false,
                                  pattern: /^[0-7]\.[0-9]$|^[0-8]$/,
                                  message: 'Please input the correct format Hours Per Day (>=0 &<=8)',
                                  trigger: 'blur',
                                  },
                                  {
                                  validator: hoursValidatorSpace,
                                  hoursIndex: index,
                                  trigger: 'blur',
                               }
                             ]"
                  >
                    <a-input
                        style="width: 96%"
                        :value="text"
                        @update:value="(val) => (segmentedData[index].hoursPerDay = val)"
                        placeholder="请输入"
                        @change="hoursPerDayChange"
                        :disabled="modifyDisabled"
                    />
                  </a-form-item>
                </template>

                <template #action="{ index,record }">
                  <a-form-item :name="[index,'action']">
                    <div v-if="index === segmentedData.length - 1" style="font-size: 1vw">
                  <span @click.stop="segmentedAdd(record)">
                    <PlusOutlined style="color: #3182CE;cursor: pointer;"/>
                  </span>
                      <a-divider type="vertical" style="background-color: #EBEBEB" />
                      <span @click.stop="segmentedDel(index,record)" :class="segmentedData.length > 2 ? 'operationLink underline' : 'operationLink disabled underline'">
                    <DeleteOutlined :style="`color: ${ segmentedData.length > 2 ? '#DC5E4F' : '#999999'};cursor: pointer`"/>
                  </span>
                    </div>
                  </a-form-item>
                </template>
              </a-table>
            </a-form>
          </a-col>


          <a-col :span="12">
            <a-form-item label="Work Location" name="workLocation" :label-col="{ span: 8 }" :wrapper-col="{ span: 24 }">
              <a-textarea
                  v-model:value="formData.workLocation"
                  placeholder="请输入"
                  showCount
                  :maxlength="50"
                  autosize="false"
                  rows="1"
                  style="width: 98%"
                  @keyup.enter="(e) => { e.stopPropagation() }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Work City" name="workCity">
              <a-select
                  ref="selectCityRef"
                  v-model:value="formData.workCity"
                  :options="workCityOps"
                  placeholder="请选择"
                  allowClear
                  show-search
                  :getPopupContainer="getPopupContainer"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" class="tipsCol">
            <a-form-item
                label="Copy"
                name="copyList"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 24 }"
            >
              <a-select
                  ref="selectEMRef"
                  v-model:value="formData.copyList"
                  placeholder="请选择"
                  :getPopupContainer="getPopupContainer"
                  showArrow
                  allowClear
                  mode="multiple"
                  v-if="!modifyDisabled"
                  @dropdownVisibleChange="copyPersonListChange"
                  style="width: 99%"
              >
                <a-select-option
                    v-for="em in copyEMOps"
                    :value="em.fullName + ' ' + em.email"
                    :key="em.fullName + ' ' + em.email"
                    :disabled="
                    formData.copyList &&
                    formData.copyList.length == 10 &&
                    formData.copyList.findIndex(
                      (i) => i === em.fullName + ' ' + em.email
                    ) === -1
                  "
                >
                  {{ em.fullName + " " + em.email }}
                </a-select-option>
              </a-select>
              <span v-if="!modifyDisabled" class="tip-text-primary" style="color:#3182CE"
              >请选择其他除LM、EM、PM、RM、HRBP以外需要抄送邮件的人员。</span
              >
              <div v-else class="copy-person">
                <div v-for="item in formData.copyList" :key="item">
                  {{ item }}
                </div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-show="isShowComment">
            <a-form-item label="RM Comments:" name="replaceReason">
              <a-textarea
                  v-model:value="formData.replaceReason"
                  placeholder="请输入"
                  showCount
                  :maxlength="200"
                  @keyup.enter="(e) => { e.stopPropagation() }"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="footer">
        <a-divider />
        <a-button type="primary" @click="submitModal" style="float: right">
          提交
        </a-button>
      </div>
    </div>
  </div>
  <OverlapModal
      :data="overlapData"
      @confirm="submitAssignment"
      v-model:visible="overlapModalVisible"
  />
  <ModalBox v-model="modelValue"
            :ok="handleOk"
            :cancel="handleCancel"
            ok-text="确定"
            cancel-text="取消"
            :content="content"
            :showIconCom="showIconCom"/>
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
//import {useStore} from "vuex";
import {message} from "ant-design-vue";
import {
  DeleteOutlined,PlusOutlined, QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import {formatCodeTerm} from "@/utils/common";
import moment from "moment/moment";
import OverlapModal from "@/components/OverlapModal";
import {useRoute} from "vue-router";
import ModalBox from "@/components/ModalBox.vue";

export default {
  name: "NoLoginAssignment",
  components: {
    ModalBox,
    PlusOutlined, DeleteOutlined,
    QuestionCircleOutlined,
    OverlapModal,
  },
  props: {
    modifyDisabled: Boolean,
    source: String,
    formState: {
      type: Object,
      default: () => ({
        serviceLine: "",
        subServiceLine: "",
        candidateName: "",
        candidateContact: "",
        candidateGrade: "",
        account: "",
        engagementCode: "",
        engagementDescription: "",
        workLocation: "",
        engagementManager: "",
        leadingCSM: "",
        copyList: [],
        assignmentTerm: [],
        estimateReleaseDate: "",
        hoursPerDay: "",
        adrc: "",
        industryLine: "",
        workCity: "",
        replaceReason: "",
        pmInfo: "",
        codeStartDate: "",
        codeEndDate: "",
        userId: '',
        demandId:'',
        pmId: '',
        preMatchingId: '',
        id: '',
        projectCompletionDate: '',
        projectStatus: '',
      }),
    },
    fillAssignmentType: String,
    accountNameOps: {
      type: Array,
      default: () => []
    },
    accountIndustryRelaction: {
      type: Array,
      default: () => []
    },
    originalCodeAndDateObj: {
      type: Object,
      default: () => {}
    },
    codeRelationList: {
      type: Object,
      default: () => {}
    },
    optionInfo: {
      type: Object,
      default: () => {}
    },
    isTimeStatus: String,
  },
  setup(props) {
    const {$api} = useGlobalPropertyHook();
    //const store = useStore();
    const formData = computed(() => props.formState);
    const accountOpts = ref([]);
    watch(
        () => props.accountNameOps,
        (val) => {
          accountOpts.value = val
        },
        {immediate: true, deep: true},
    )
    const formRef = ref();
    const workCityOps = computed(() => props.optionInfo.workCityOps);
    const EMOps = computed(() => props.optionInfo.EMOps);
    const copyEMOps = ref([]);
    const selectCityRef = ref();
    const selectEMRef = ref();
    const isShowComment = ref(false);
    const isSendEmail = ref('2')
    const timeStatus = computed(() => props.isTimeStatus)
    const route = useRoute();
    //获取序列号
    const serialNo = computed(() => route.params.id)

    const formRules = reactive({
      engagementCode: [
        {
          required: true,
          message: "Please select Engagement Code",
          trigger: "blur",
        },
      ],
      engagementManager: [
        {
          required: true,
          message: "Please select Engagement Manager",
          trigger: "blur",
        },
      ],
      leadingCSM: [
        {
          required: true,
          message: "Please select Leading CSM",
          trigger: "blur",
        },
      ],
      assignmentTerm: [
        {
          required: true,
          message: "Please input Assignment Term",
          trigger: ["change", "blur"],
          type: "array",
        },
      ],
      account: [
        {
          required: true,
          message: "Please input Account",
          trigger: "blur",
        },
      ],
      engagementDescription: [
        {
          required: true,
          trigger: "blur",
          validator: async (rule, value) => {
            if (value && value.trim()) {
              if(/^[\u4e00-\u9fa5'’‘]{1,}$/.test(value)) {
                return Promise.reject('不支持中文、引号');
              }else {
                return Promise.resolve();
              }
            }else {
              return Promise.reject('Please input Engagement Description');
            }
          }
        },
      ],
      projectCommitDate: [
        {
          required: true,
          message: "Please input Code预计开出时间",
          trigger: "change",
        },
      ],
      workCity: [
        {
          required: true,
          message: "Please select Work City",
          trigger: "blur",
        },
      ],
      workLocation: [
        {
          required: true,
          message: "Please input Work Location",
          trigger: "blur",
        },
      ],
      hoursPerDay: [
        {
          required: true,
          message: "Please input Hours Per Day",
          trigger: "blur",
        },
        {
          required: false,
          pattern: /^[0-7]\.[1-9]$|^[1-8]$/,
          message: "Please input the correct format Hours Per Day (>0 & <=8)",
          trigger: ["blur"],
        },
      ],
      replaceReason: [],
    });

    const engagementCodeOpts = ref([])
    // 正式code为true
    const isFormalCode = ref(true)

    const isDisableProjectCommitDate = ref(true)

    const placeHolderTip = computed(() =>  isDisableProjectCommitDate.value ? "仅适用于TBD Code" : "请选择");

    const codeRelationList = computed(() => props.codeRelationList)

    watch(
        () => codeRelationList.value,
        (val) => {
          engagementCodeOpts.value = formData.value.account ? getFilterredCodeOpt() : val.allCodeOptionsList
        },
        {immediate: true, deep: true},
    )

    const codeTerm = computed(() => {
      return {
        codeStartDate: formData.value.codeStartDate,
        codeEndDate: formData.value.codeEndDate,
      }
    })
    watch(
        () => codeTerm.value,
        (val) => {
          formData.value.codeTerm = formatCodeTerm(val.codeStartDate, val.codeEndDate)
        },
        {
          immediate: true
        }
    )

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalFlag = ref('')
    const handleOk = () => {
      modelValue.value = false
      if (modalFlag.value === '1') {
        setDataFromCode(resData.value)
      } else {
        handleTimeJudgment();
      }
    }
    const handleCancel = () => {
      modelValue.value = false
      if (modalFlag.value === '1') {
        formData.value.engagementCode = ""
      }
    }

    //接收code状态
    const projectStatus = ref()
    const resData = ref()
    const getSelectedCodeInfo = (engagementCode) => {
      const codeInfo = codeRelationList.value.allCodeOptionsList.filter((item) => engagementCode === item.engagementCode)
      return codeInfo
    }
    const handleCodeChange = async () => {
      // 获取最新的关系数据，获取最新的下拉列表
      formData.value.engagementCode = formData.value.engagementCode?.split('(')[0].trim()
      //为空则初始化account和code下拉框
      if(!formData.value.engagementCode) {
        accountOpts.value = props.accountNameOps
        engagementCodeOpts.value = codeRelationList.value.allCodeOptionsList
        formData.value.account = undefined
      }
      const param = {
        engagementCode: formData.value.engagementCode,
      }
      const { data } = await $api.getCodeDataQueryByEngagementCode(serialNo.value,param)
      const selectedCodeInfo = data
      // 如果Engagement Code填写的类型为IDC, BDC 或 SFC时，输入后则弹窗提示：当前填写Code非DC Code，是否继续填写？
      // 点击确认，则回到调令填写页面，code字段为已选择的code；点击取消，则选择框置空
      resData.value = data
      //接受code的状态
      projectStatus.value = data.projectStatus
      if(!selectedCodeInfo) return
      if (selectedCodeInfo && selectedCodeInfo.codeType !== 'DC') {
        content.value = '当前填写Code为Non-Billable Code，是否继续填写？'
        showIconCom.value = 'ExclamationCircleOutlined'
        modalFlag.value = '1'
        modelValue.value = true
        //  提示弹窗
        // Modal.confirm({
        //   title: () => '提示',
        //   content: "",
        //   icon: createVNode(ExclamationCircleOutlined),
        //   okText: "确认",
        //   cancelText: "取消",
        //   centered: true,
        //   onOk() {
        //     setDataFromCode(selectedCodeInfo)
        //     // console.log('ok');
        //     // Modal.visible = false
        //     // console.log('destroy')
        //     // Modal.destroyAll();
        //   },
        //   onCancel() {
        //     formData.value.engagementCode = ""
        //     // console.log('Cancel');
        //     // Modal.visible = false
        //     // console.log('destroy')
        //     // Modal.destroyAll();
        //   },
        // });
      } else {
        setDataFromCode(selectedCodeInfo);
      }
      // 为正式code时 设置engagementDescription默认值
      if (props.source === "assignmentDetail" || props.fillAssignmentType === "RMConfirm") {
        formData.value.projectCommitDate = getDefaultCommitDate()
      }
    }
    const handleAccountChange = () => {
      if(!formData.value.account) {
        // 筛选code下拉列表
        engagementCodeOpts.value = codeRelationList.value.allCodeOptionsList
        accountOpts.value = props.accountNameOps
        formData.value.engagementCode = undefined
      }
    }
    // 设置跟code变化的数据
    const setDataFromCode = (selectedCodeInfo) => {
      // Engagement Code填写后，自动查询EM值，Engagement Manager需在code主数据中自动带出，不可编辑
      formData.value.engagementManager = selectedCodeInfo.engagementManager
      formData.value.leadingCSM = selectedCodeInfo.leadingCSM
      handleDisableEm(selectedCodeInfo.codeType);
      // 获取Code有效期
      if (projectStatus.value === 'Close') {
        formData.value.codeEndDate = selectedCodeInfo.codeEndDate
      } else {
        formData.value.codeEndDate = selectedCodeInfo.projectCompletionDate
      }
      formData.value.codeStartDate = selectedCodeInfo.codeStartDate
      // 获取account默认值
      formData.value.account = selectedCodeInfo.account ? selectedCodeInfo.account : undefined

      isFormalCode.value = selectedCodeInfo.type === 'code'
      formData.value.engagementDescription = isFormalCode.value ? selectedCodeInfo.engagementDescription : ""
      // projectCommitDate 校验
      if (isFormalCode.value) formData.value.projectCommitDate = ""
      // engagementDescription默认值
      initStateWithCodeType(isFormalCode.value)
      handleDisableCommitDate();
    }

    const initStateWithCodeType = (isFormalCode) => {
      formRules.projectCommitDate[0].required = !isFormalCode
      formRules.leadingCSM[0].required = !isFormalCode
    }

    watch(
        () => formData.value.engagementCode,
        (val) => {
          if (val) {
            val = val?.split('(')[0].trim()
            const selectDataList = getSelectedCodeInfo(val)
            if (selectDataList?.length > 0) {
              const selectedCodeInfo = selectDataList[0]
              isFormalCode.value = selectedCodeInfo.type === 'code'
              initStateWithCodeType(isFormalCode.value)
              handleDisableEm(selectedCodeInfo.codeType);
            }
            // 筛选account下拉列表
            accountOpts.value = codeRelationList.value.relationsList.filter((item) => item.engagementCode === val).map((item) => {
              return {
                ...item,
                label: item.account,
                value: item.account,
              }
            })
          }else{
            isFormalCode.value = true
            formData.value.engagementManager = ''
          }
        },
        {deep: true})

    watch(
        () => formData.value.account,
        (val) => {
          if (val) {
            const relatedData = props.accountIndustryRelaction.find(
                (item) => item.account === val
            );
            // 获取account对应的industryLine
            formData.value.industryLine = timeStatus.value === '4'? formData.value.industryLine : (relatedData? relatedData.industryLine : formData.value.industryLine)
            engagementCodeOpts.value = getFilterredCodeOpt()
            console.log('1111111111',props.accountIndustryRelaction,relatedData,formData.value.industryLine)
          }
        },
    );
    const getFilterredCodeOpt = () => {
      const codeOpts = codeRelationList.value.allCodeOptionsList.filter((item) => item.account === formData.value.account)
      return codeOpts;
    }
    const disabledCommitDate = (current) => {
      if(moment().diff(formData.value.assignmentTerm[0],'day') <= 0){
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))
            && (moment(current.format("YYYY-MM-DD")) >= moment(projectCommitStartDate.value))) return false
        return true;
      } else {
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))) return false
        return true;
      }
    }

    const annexRightMsg = ref("")

    const handleDisableCommitDate = () => {
      if (isFormalCode.value) {
        isDisableProjectCommitDate.value = true
      } else {
        if (formData.value.assignmentTerm && formData.value.assignmentTerm.length < 1) {
          isDisableProjectCommitDate.value = true
          return
        } else {
          if (props.source === "assignmentDetail" || props.fillAssignmentType === "RMConfirm") {
            //
            isDisableProjectCommitDate.value = (formData.value.engagementCode === props.originalCodeAndDateObj.engagementCode)
                && (props.originalCodeAndDateObj.onBoardDate === moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"))
          } else {
            isDisableProjectCommitDate.value = false
          }
        }

      }
    }
    const isAbleEm = ref(false)
    const handleDisableEm = (codeType) => {
      // 可填写
      isAbleEm.value = isFormalCode.value && codeType !== 'DC'
    }

    const handleAssChange = () => {
      if (formData.value.assignmentTerm[0] && formData.value.assignmentTerm[1]) {
        formData.value.projectCommitDate = getDefaultCommitDate()
        // tbd 类型则
        handleDisableCommitDate()
      }
    }
    const getDefaultCommitDate = () => {
      const originalOnBoardDate = props.originalCodeAndDateObj.onBoardDate
      const originalCode = props.originalCodeAndDateObj.engagementCode
      const isSameCode = originalCode && (originalCode === formData.value.engagementCode)
      const isSameOnBoardDate = originalOnBoardDate && (originalOnBoardDate === moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"))
      if(isSameCode && isSameOnBoardDate) {
        return props.originalCodeAndDateObj.projectCommitDate
      }else {
        return ''
      }
    }
    watch(
        () => formData.value.assignmentTerm,
        (val) => {
          if(val) {
            if (val[0] && val[1]) {
              getCommitDateRange()
              if(!isFormalCode.value) {
                isDisableProjectCommitDate.value = false
              }
            } else {
              isDisableProjectCommitDate.value = true
            }
          }
        },
        {immediate:true, deep:true}
    )
    const projectCommitEndDate = ref("")
    const projectCommitStartDate = ref("")
    const getCommitDateRange = () => {
      if(timeStatus.value === '0') {
        if (formData.value.assignmentTerm.length > 0) {
          const params = {
            onboardDate: moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"),
          }
          $api.projectCommitDateRange(serialNo.value, params).then((res) => {
            projectCommitEndDate.value = res.data.projectCommitEndDate
            projectCommitStartDate.value = res.data.projectCommitStartDate
          });
        }
      }
    }

    // 设置RM COMMENTS校验规则
    const getFormRulesOptions = () => {
      formRules.replaceReason.splice(0)
      const commentRules = {
        required: true,
        message: "Please input RM Comments",
        trigger: "blur",
      }
      formRules.replaceReason.push(commentRules)
      return formRules.replaceReason
    };
    watch(
        () => props.fillAssignmentType,
        (val) => {
          isShowComment.value = val === 'RMFillAssignment'
          formRules.replaceReason = val === 'RMFillAssignment' ? getFormRulesOptions() : []
        },
        {immediate: true})

    // watch(
    //     () => props.visible,
    //     (val) => {
    //       //selectIsSend()
    //       if(val) {
    //         formRef.value?.clearValidate();
    //         isSendEmail.value = '2';
    //       }
    //       if(!val) isFormalCode.value=true
    //       if (val && props.rejectBtn === true) {
    //         $api.checkFormalCode({engagementCode : formData.value.engagementCode}).then((res) => {
    //           isFormalCode.value = !res.data
    //         });
    //       }
    //     }
    // )

    //判断是否是正式的code
    const checkIsFormalCode = () => {
      const params = {
        engagementCode : formData.value.engagementCode,
      }
      $api.checkCodeByEngagementCode(serialNo.value,params).then((res) => {
        isFormalCode.value = !res.data
      });
    }
    watch(
        () => timeStatus.value,
        (val) => {
          if(val === '0' && formData.value.engagementCode){
            checkIsFormalCode()
            if(formData.value.assignmentTerm.length > 0){
              getCommitDateRange()
            }
          }
        }
    )

    onMounted(() => {

    });


    const submitModal = () => {
      //if(isFormalCode.value === true) formRules.leadingCSM[0].required = false
      formRef.value.validate().then(async () => {
        const params = {
          code: formData.value.engagementCode,
          onboardDate: moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"),
          estimateReleaseDate: moment(formData.value.assignmentTerm[1]).format("YYYY-MM-DD"),
        }
        const { data } = await $api.pmCheckCodeTerm(serialNo.value,params)
        if(data) {
          content.value = 'Assignment Term与Code有效期冲突,请确认是否继续'
          showIconCom.value = 'ExclamationCircleOutlined'
          modelValue.value = true
          // Modal.confirm({
          //   title: () => '提示',
          //   content: 'Assignment Term与Code有效期冲突,请确认是否继续',
          //   icon: createVNode(ExclamationCircleOutlined),
          //   okText: "确认",
          //   cancelText: "取消",
          //   centered: true,
          //   onOk() {
          //     handleTimeJudgment();
          //     // Modal.visible = false;
          //   }
          // })
        }else {
          handleTimeJudgment();
        }
      });
    };

    const overlapData = ref({
      status: "prohibit",
      assignmentList: [],
      workTaskList: [],
      overlapCommandList: [],
      currentformData: {},

    });
    const overlapModalVisible = ref(false);
    const handleTimeJudgment = () => {
      const params = {
        assignmentAndCopyCommand: {
          account: formData.value.account,
          actualReleaseDate: moment(formData.value.assignmentTerm[1]).format("YYYY-MM-DD"),
          assEmailStatus: 0,
          candidateContact: formData.value.candidateContact,
          candidateGrade: formData.value.candidateGrade,
          candidateName: formData.value.candidateName,
          clientName: formData.value.clientName,
          codeEndDate: formData.value.codeEndDate,
          codeStartDate: formData.value.codeStartDate,
          copyList: formData.value.copyList,
          copyPersonName: "",
          costCenter: formData.value.costCenter,
          costCtr: formData.value.costCtr,
          createOperator: "",
          createTime: "",
          demandClientName: "",
          demandId: formData.value.demandId,
          demandIndustryLine: "",
          engagementCode: formData.value.engagementCode,
          engagementContactPerson: "",
          engagementDescription: formData.value.engagementDescription,
          engagementManager: formData.value.engagementManager,
          estimateReleaseDate: "",
          hoursPerDay: formData.value.hoursPerDay,
          id: formData.value.id,
          industryLine: formData.value.industryLine,
          leadingCSM: "",
          onBoardDate: moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"),
          operationTime: "",
          operator: "",
          pmEmail: formData.value.pmInfo,
          pmId: formData.value.pmId,
          pmInfo: formData.value.pmInfo,
          pmName: "",
          preMatchingId: formData.value.preMatchingId,
          projectCommitDate: formData.value.projectCommitDate,
          psGroup: "",
          rejectReason: "",
          releaseEmailStatus: 0,
          releaseMode: "",
          releaseReason: "",
          rmId: formData.value.rmId,
          serviceLine: formData.value.serviceLine,
          source: "",
          status: "",
          subServiceLine: formData.value.subServiceLine,
          userId: formData.value.userId,
          workCity: formData.value.workCity,
          workLocation: formData.value.workLocation,
          staffAssignmentParts: segmentedData.value
        },
        staffAssignment: {
          account: formData.value.account,
          actualReleaseDate: moment(formData.value.assignmentTerm[1]).format("YYYY-MM-DD"),
          assEmailStatus: 0,
          candidateContact: formData.value.candidateContact,
          candidateGrade: formData.value.candidateGrade,
          candidateName: formData.value.candidateName,
          clientName: formData.value.clientName,
          codeEndDate: formData.value.codeEndDate,
          codeStartDate: formData.value.codeStartDate,
          copyList: formData.value.copyList,
          copyPersonName: "",
          costCenter: formData.value.costCenter,
          costCtr: formData.value.costCtr,
          createOperator: "",
          createTime: "",
          demandClientName: "",
          demandId: formData.value.demandId,
          demandIndustryLine: "",
          engagementCode: formData.value.engagementCode,
          engagementContactPerson: "",
          engagementDescription: formData.value.engagementDescription,
          engagementManager: formData.value.engagementManager,
          estimateReleaseDate: "",
          hoursPerDay: formData.value.hoursPerDay,
          id: formData.value.id,
          industryLine: formData.value.industryLine,
          leadingCSM: "",
          onBoardDate: moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"),
          operationTime: "",
          operator: "",
          pmEmail: formData.value.pmInfo,
          pmId: formData.value.pmId,
          pmInfo: formData.value.pmInfo,
          pmName: "",
          preMatchingId: formData.value.preMatchingId,
          projectCommitDate: formData.value.projectCommitDate,
          psGroup: "",
          rejectReason: "",
          releaseEmailStatus: 0,
          releaseMode: "",
          releaseReason: "",
          rmId: formData.value.rmId,
          serviceLine: formData.value.serviceLine,
          source: "",
          status: "",
          subServiceLine: formData.value.subServiceLine,
          userId: formData.value.userId,
          workCity: formData.value.workCity,
          workLocation: formData.value.workLocation,
        }
      }
      $api.confirmPm(serialNo.value,'0',params).then((res) => {
        if(res.code !== 200) {
          message.error(res.msg)
          return
        }
        if(res.data){
          if (res.data.gatherFlag === 'false') {
            // 时间不重叠
            //submitAssignment();
            message.success(res.msg);
            location.reload();
          } else {
            let currentOverlapData = {
              clientName: formData.value.account,
              engagementCode: formData.value.engagementCode,
              engagementDescription: formData.value.engagementDescription,
            }
            overlapData.value = {
              status: res.data.gatherFlag,
              assignmentList: res.data.assignmentQueryPartCommandList || [],
              workTaskList: res.data.workTaskList || [],
              overlapCommandList: res.data.overlapCommandList || [],
              currentformData: currentOverlapData,
              demandList: res.data.demandList || [],
            }
            overlapModalVisible.value = true;
          }
        } else {
          message.success(res.msg);
          location.reload();
        }
      });
    }

    const submitAssignment = () => {
      const params = {
        assignmentAndCopyCommand: {
          account: formData.value.account,
          actualReleaseDate: moment(formData.value.assignmentTerm[1]).format("YYYY-MM-DD"),
          assEmailStatus: 0,
          candidateContact: formData.value.candidateContact,
          candidateGrade: formData.value.candidateGrade,
          candidateName: formData.value.candidateName,
          clientName: formData.value.clientName,
          codeEndDate: formData.value.codeEndDate,
          codeStartDate: formData.value.codeStartDate,
          copyList: formData.value.copyList,
          copyPersonName: "",
          costCenter: formData.value.costCenter,
          costCtr: formData.value.costCtr,
          createOperator: "",
          createTime: "",
          demandClientName: "",
          demandId: formData.value.demandId,
          demandIndustryLine: "",
          engagementCode: formData.value.engagementCode,
          engagementContactPerson: "",
          engagementDescription: formData.value.engagementDescription,
          engagementManager: formData.value.engagementManager,
          estimateReleaseDate: "",
          hoursPerDay: formData.value.hoursPerDay,
          id: formData.value.id,
          industryLine: formData.value.industryLine,
          leadingCSM: "",
          onBoardDate: moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"),
          operationTime: "",
          operator: "",
          pmEmail: formData.value.pmInfo,
          pmId: formData.value.pmId,
          pmInfo: formData.value.pmInfo,
          pmName: "",
          preMatchingId: formData.value.preMatchingId,
          projectCommitDate: formData.value.projectCommitDate,
          psGroup: "",
          rejectReason: "",
          releaseEmailStatus: 0,
          releaseMode: "",
          releaseReason: "",
          rmId: formData.value.rmId,
          serviceLine: formData.value.serviceLine,
          source: "",
          status: "",
          subServiceLine: formData.value.subServiceLine,
          userId: formData.value.userId,
          workCity: formData.value.workCity,
          workLocation: formData.value.workLocation,
          staffAssignmentParts: segmentedData.value
        },
        staffAssignment: {
          account: formData.value.account,
          actualReleaseDate: moment(formData.value.assignmentTerm[1]).format("YYYY-MM-DD"),
          assEmailStatus: 0,
          candidateContact: formData.value.candidateContact,
          candidateGrade: formData.value.candidateGrade,
          candidateName: formData.value.candidateName,
          clientName: formData.value.clientName,
          codeEndDate: formData.value.codeEndDate,
          codeStartDate: formData.value.codeStartDate,
          copyList: formData.value.copyList,
          copyPersonName: "",
          costCenter: formData.value.costCenter,
          costCtr: formData.value.costCtr,
          createOperator: "",
          createTime: "",
          demandClientName: "",
          demandId: formData.value.demandId,
          demandIndustryLine: "",
          engagementCode: formData.value.engagementCode,
          engagementContactPerson: "",
          engagementDescription: formData.value.engagementDescription,
          engagementManager: formData.value.engagementManager,
          estimateReleaseDate: "",
          hoursPerDay: formData.value.hoursPerDay,
          id: formData.value.id,
          industryLine: formData.value.industryLine,
          leadingCSM: "",
          onBoardDate: moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD"),
          operationTime: "",
          operator: "",
          pmEmail: formData.value.pmInfo,
          pmId: formData.value.pmId,
          pmInfo: formData.value.pmInfo,
          pmName: "",
          preMatchingId: formData.value.preMatchingId,
          projectCommitDate: formData.value.projectCommitDate,
          psGroup: "",
          rejectReason: "",
          releaseEmailStatus: 0,
          releaseMode: "",
          releaseReason: "",
          rmId: formData.value.rmId,
          serviceLine: formData.value.serviceLine,
          source: "",
          status: "",
          subServiceLine: formData.value.subServiceLine,
          userId: formData.value.userId,
          workCity: formData.value.workCity,
          workLocation: formData.value.workLocation,
        }
      }
      $api.confirmPm(serialNo.value,'1',params).then((res) => {
        message.success(res.msg);
        location.reload();
      });
    }


    const disabledDate = (current) => {
      if (current && props.formState.staffTerminationDate && moment(current.format("YYYY-MM-DD")) > moment(props.formState.staffTerminationDate)) return true
      if (current && props.formState.staffOnboardDate && moment(current.format("YYYY-MM-DD")) < moment(props.formState.staffOnboardDate)) return true
      return false
    }
    const getPopupContainer = triggerNode => {
      return triggerNode.parentNode || document.body;
    }
    const copyPersonListChange = (state) => {
      if(formData.value.copyList.length > 0 && state === true) {
        let selectCC = copyEMOps.value.filter(item => formData.value.copyList.includes(item.fullName + ' ' + item.email))
        copyEMOps.value = selectCC.concat(copyEMOps.value.filter(item => !formData.value.copyList.includes(item.fullName + ' ' + item.email)))
      }
    }

    const onBoardDisabledDate = (current) => {
      if(formData.value.assignmentTerm[1]) {
        return moment(formData.value.assignmentTerm[1]) < current
      }
    };
    const estimateReleaseDisabledDate = (current) => {
      if(formData.value.assignmentTerm[0]) {
        return current < moment(formData.value.assignmentTerm[0])
      }
    };
    watch(
        () => props.optionInfo.EMOps,
        (val) => {
          if(val) {
            copyEMOps.value = val
          }
        }
    )

    //分段编辑
    const isSegmentedEditing = ref(true)
    const segmentedData = ref([])
    const columns = [
      {
        title: "分段Assignment Term",
        dataIndex: "startDate",
        ellipsis: true,
        width: `26%`,
        slots: { customRender: "startDate" },
      },
      {
        title: " ",
        dataIndex: "endDate",
        ellipsis: true,
        width: `25.5%`,
        slots: { customRender: "endDate" },
      },
      {
        title: "分段Hours Per Day",
        dataIndex: "hoursPerDay",
        ellipsis: true,
        width: `25.5%`,
        slots: { customRender: "hoursPerDay" },
      },
      {
        title: "",
        ellipsis: true,
        width: `23%`,
        slots: { customRender: "action" },
      },
    ];

    const segmentedFormRules = {
      startDate: [
        {
          required: true,
          message: 'Please input Assignment Term',
          trigger: 'change',
        }
      ],
      endDate: [
        {
          required: true,
          message: 'Please input Assignment Term',
          trigger: 'change',
        }
      ],
      hoursPerDay: [
        {
          required: true,
          message: 'Please input Hours Per Day',
          trigger: 'change',
        },
        {
          required: false,
          pattern: /^[0-7]\.[1-9]$|^[1-8]$/,
          message: 'Please input the correct format Hours Per Day (>0 & <=8)',
          trigger: 'blur',
        },
      ],
    };
    // watch(
    //     () => props.formState.staffAssignmentParts,
    //     (val) => {
    //       debugger
    //       if(val) {
    //         isSegmentedEditing.value = val.length > 0 ? false : true
    //         segmentedData.value = props.formState.staffAssignmentParts
    //       }
    //     },
    // )
    const segmentedAdd = () => {
      if(segmentedData.value[segmentedData.value.length-1].endDate) {
        console.log(111)
        let newRow = {
          hoursPerDay: undefined,
          startDate: moment(segmentedData.value[segmentedData.value.length-1].endDate).add(1, 'days').format('YYYY-MM-DD'),
          endDate: undefined,
        }
        segmentedData.value.push(newRow)
      } else {
        let newRow = {
          hoursPerDay: undefined,
          startDate: undefined,
          endDate: undefined,
        }
        segmentedData.value.push(newRow)
      }
    }
    const segmentedDel = (index) => {
      segmentedData.value.splice(index,1)
      let arr = segmentedData.value.map(item => item.hoursPerDay).filter(item => item)
      formData.value.hoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      formData.value.assignmentTerm[1] = segmentedData.value[index-1].endDate
    }

    const segmentedEditingClick = () => {
      isSegmentedEditing.value = false
      if(segmentedData.value.length === 0){
        segmentedData.value = [
          {
            id:'',
            hoursPerDay: undefined,
            startDate: '',
            endDate: '',
            assignmentId: '',
          },
          {
            id:'',
            hoursPerDay: undefined,
            startDate: '',
            endDate: '',
            assignmentId: '',
          },
        ]
      }
    }
    const cancelSegmentedEditingClick = () => {
      isSegmentedEditing.value = true
      segmentedData.value = []
    }

    const startDateChange = (index,record) => {
      console.log(index,record)
      if(index > 0 && record.startDate && !segmentedData.value[index - 1].endDate){
        segmentedData.value[index - 1].endDate = moment(record.startDate).subtract(1, 'days').format("YYYY-MM-DD")
      }
      if(segmentedData.value[0].startDate) {
        formData.value.assignmentTerm[0] = segmentedData.value[0].startDate
        if(segmentedData.value[segmentedData.value.length-1].endDate) {
          formData.value.projectCommitDate = getDefaultCommitDate()
          handleDisableCommitDate()
        }
      }
    }

    const endDateChange = (index,record) => {
      if(index < segmentedData.value.length-1 && record.endDate) {
        segmentedData.value[index + 1].startDate = moment(record.endDate).add(1, 'days').format("YYYY-MM-DD")
      } else {
        formData.value.assignmentTerm[1] = segmentedData.value[segmentedData.value.length-1].endDate
        if(segmentedData.value[0].startDate) {
          formData.value.projectCommitDate = getDefaultCommitDate()
          handleDisableCommitDate()
        }
      }
    }
    const hoursPerDayChange = () => {
      let arr = segmentedData.value.map(item => item.hoursPerDay).filter(item => item)
      formData.value.hoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      //formData.value.hoursPerDay = segmentedData.value.map(item => item.hoursPerDay).reduce((prev,curr)=> (Number(prev)*10+Number(curr)*10)/10)
    }

    const segmenteOnBoardDisabledDate = (current,record,index) => {
      if(segmentedData.value[index].endDate) {
        return current > moment(record.endDate)
      }
    };
    const segmenteEstimateReleaseDate = (current,record,index) => {
      if(segmentedData.value[index].startDate) {
        return current < moment(record.startDate)
      }
    };

    const startValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Assignment Term");
      }else {
        if (rule.startDateIndex > 0 && segmentedData.value[rule.startDateIndex - 1].endDate) {
          let endDate = moment(segmentedData.value[rule.startDateIndex - 1].endDate).add(1, 'days')
          if (!moment(value).isSame(endDate)) {
            return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
          }
          // if(segmentedData.value[rule.startDateIndex].endDate) {
          //   if (!moment(value).isBefore(moment(segmentedData.value[rule.startDateIndex].endDate).add(1, 'days'))) {
          //     return Promise.reject("您所选择的时间不符合规定");
          //   } else {
          //     return Promise.resolve();
          //   }
          // }
        }else {
          return Promise.resolve();
        }
      }
    };
    const endValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Assignment Term");
      }else {
        if (rule.endDateIndex < segmentedData.value.length - 1) {
          if (segmentedData.value[rule.endDateIndex + 1].startDate) {
            let startDate = moment(segmentedData.value[rule.endDateIndex + 1].startDate).subtract(1, 'days')
            if (!moment(value).isSame(startDate)) {
              return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
            }
          } else {
            return Promise.resolve();
          }
        }
        if(segmentedData.value[rule.endDateIndex].startDate) {
          if (!moment(value).isAfter(moment(segmentedData.value[rule.endDateIndex].startDate).subtract(1, 'days'))) {
            return Promise.reject("您所选择的时间不符合规定");
          } else {
            return Promise.resolve();
          }
        }
      }
    };
    const hoursValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Hours Per Day");
      }else {
        if (rule.hoursIndex > 0) {
          if (value === segmentedData.value[rule.hoursIndex - 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else if (rule.hoursIndex < segmentedData.value.length - 1) {
            if (value === segmentedData.value[rule.hoursIndex + 1].hoursPerDay) {
              return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
            }
          } else {
            return Promise.resolve();
          }
        } else {
          if (value === segmentedData.value[rule.hoursIndex + 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else {
            return Promise.resolve();
          }
        }
      }
    }


    return {
      labelCol: { span: 12 },
      wrapperCol: { span: 23 },
      submitModal,
      formData,
      formRef,
      formRules,
      workCityOps,
      getFormRulesOptions,
      EMOps,
      copyEMOps,
      selectCityRef,
      selectEMRef,
      //rollBackModal,
      disabledDate,
      isShowComment,
      getPopupContainer,
      engagementCodeOpts,
      isFormalCode,
      placeHolderTip,
      isDisableProjectCommitDate,
      disabledCommitDate,
      handleAssChange,
      annexRightMsg,
      projectCommitEndDate,
      projectCommitStartDate,
      accountOpts,
      codeTerm,
      handleCodeChange,
      isAbleEm,
      handleAccountChange,
      getFilterredCodeOpt,
      isSendEmail,
      checkIsFormalCode,
      overlapData,
      overlapModalVisible,
      submitAssignment,
      timeStatus,
      serialNo,
      copyPersonListChange,
      onBoardDisabledDate,
      estimateReleaseDisabledDate,
      projectStatus,
      resData,
      getCommitDateRange,
      isSegmentedEditing,
      columns,
      segmentedData,
      segmentedAdd,
      segmentedDel,
      segmentedEditingClick,
      cancelSegmentedEditingClick,
      segmentedFormRules,
      startDateChange,
      endDateChange,
      hoursPerDayChange,
      segmenteOnBoardDisabledDate,
      segmenteEstimateReleaseDate,
      startValidatorSpace,
      endValidatorSpace,
      hoursValidatorSpace,
      modelValue,
      content,
      showIconCom,
      handleOk,
      handleCancel
    };
  },
}
</script>

<style scoped lang="less">
.open-page {
  height: 40px;
  display: flex;
  align-items: center;
  background: var(--unnamed, #FAFAFA);
  border-bottom: 0.1vw solid rgba(0,0,0,.08);

  .tag {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: auto;
    margin-left: 20px;
  }

  .ant-tag {
    background: #fff;
    padding: 0.4vw 1.8vw 0.4vw 1.5vw;
    color: #303133;
    position: relative;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    border: 0 solid #d9d9d9;
    border-top: 0.10417vw solid #3182CE;
  }

  .active-page-tag {
    background: #ECF5FE;
    border-top: 0.10417vw solid #3182CE;
  }
}
.main-form{
  height: calc(100vh - 200px);
  overflow-y: auto;
  .ant-form{
    margin: 20px 0 0 40px;

    .ant-calendar-picker-input {
      border: 1px solid #cccccc !important;
    }
  }
  :deep(.ant-input){
    border: 1px solid #cccccc !important;
  }
  :deep(.ant-select-selector){
    border: 1px solid #cccccc !important;
  }
  :deep(.ant-select-arrow){
    color: #cccccc;
  }
  .engagementCodeCol {
    :deep(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after){
      content: "";
    }
    .codeLabel{
      margin-right: 0.3vw;
      &::after{
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }
  }
  .footer{
    width: 100%;
    position: fixed;
    bottom: 14px;
    padding: 0 80px 0 30px;
    :deep(.ant-divider-horizontal) {
      margin:14px 0 ;
    }
  }
  :deep(.ant-form-item-label>label){
    font-size: 0.9vw;
  }
  .labelColor {
    :deep(.ant-form-item-label > label){
      color: #999999;
    }
  }
  .red-text {
    :deep(.ant-form-item-label) {
      > label {
        color: #ff4d4f;
      }
    }
    :deep(.ant-form-item-control-input-content){
      color: #ff4d4f;
      font-size: 0.9vw;
    }
    :deep(.ant-form-item){
      margin-bottom: 0;
      align-items: center;
    }
  }
  :deep(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    display: none;
  }
  :deep(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after){
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  :deep(.ant-form-vertical .ant-form-item-label ){
    padding-bottom: 0.1vw;
    font-size:1vw;
    font-family: PingFang SC;
  }
  .assignmentTermClass{
    :deep(.ant-form-item-control-input-content){
      display: flex;
      align-items: center;
    }
    .content{
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #999999;
      margin: 0 0.18vw
    }
  }
}
.segmentedClass {
  :deep(.ant-table .ant-table-thead > tr > th){
    padding-left: 0;
  }
  :deep(.ant-table .ant-table-tbody > tr > td) {
    //padding-left: 0;
    //padding-right: 0;
    padding: 1vw 0;
  }
  .tilde{
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
    margin: 0 -0.16vw
  }
  :deep(.ant-form-item) {
    flex-direction: row;
  }
}
.segmentedtaleclass {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 3vw 0 0!important;

  :deep(.ant-form-item-has-error) {
    .ant-col {
      min-height: auto;
    }
  }
}
</style>
